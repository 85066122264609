export const DATA_READY = 'DATA_READY'
export const DATA_SEARCH = 'DATA_SEARCH'
export const API_ERROR = 'API_ERROR'

export const GET_PAGE = 'GET_PAGE'
export const GET_PAGE_READY = 'GET_PAGE_READY'
export const GET_MAP = 'GET_MAP'
export const GET_MAP_READY = 'GET_MAP_READY'
export const GET_ARTWORK = 'GET_ARTWORK'
export const GET_ARTWORK_READY = 'GET_ARTWORK_READY'
export const GET_ARTISTS = 'GET_ARTISTS'
export const GET_ARTISTS_READY = 'GET_ARTISTS_READY'
export const GET_IMAGE = 'GET_IMAGE'
export const GET_IMAGE_READY = 'GET_IMAGE_READY'
export const GET_NEARBY_ARTWORKS = 'GET_NEARBY_ARTWORKS'
export const GET_NEARBY_ARTWORKS_READY = 'GET_NEARBY_ARTWORK_READY'
export const GET_ARTWORKS_BY_ARTIST = 'GET_ARTWORKS_BY_ARTIST'
export const GET_ARTWORKS_BY_ARTIST_READY = 'GET_ARTWORKS_BY_ARTIST_READY'
export const GET_SEARCH = 'GET_SEARCH'
export const GET_SEARCH_READY = 'GET_SEARCH_READY'